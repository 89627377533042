.goalSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px 150px;
  padding-bottom: 0;
}

.goalSection h2 {
  text-transform: uppercase;
}

.goalTexts {
  width: 65%;

  font-weight: 500;
  font-size: 20px;
  text-align: justify;
  color: #000;
}

.methodologySection {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 90px 150px;
}

.methodologySection h2 {
  text-transform: uppercase;
  margin-bottom: 50px;
}

.methodologyTexts {
  width: 65%;
  margin-right: 50px;
}
.methodologyTexts p {
  margin: 0;
  font-size: 20px;
}

.methodologyTitle {
  font-weight: bolder;
}

.methodologyDescription {
  margin-bottom: 20px !important;
  font-weight: 500;
}

.methodologySection img {
  width: 30%;
}

.responsiveImg {
  display: none;
}
@media screen and (max-width: 640px) {
  .responsiveImg {
    display: flex;
    margin: 20px 0;
    width: 100%;
  }
  .responsiveImg img {
    width: 100%;
    height: 100%;
  }
  .goalSection,
  .methodologySection {
    flex-direction: column;
    padding: 0 50px;
  }

  .goalTexts,
  .methodologyTexts {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }

  .methodologyTexts{
    margin-right: 0;
    margin-bottom: 50px;
  }

  .originalImage {
    display: none;
  }

  
.goalSection h2 {
  margin-top: 20px
}
}
