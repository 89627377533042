.timeSection {
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 90px 150px 50px 150px;
  background-color: var(--cinza);
}

.barra {
  width: 5px;
  height: 100px;
  background-color: var(--preto);
}

.timeSection div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeTexts {
  margin-left: 30px;
}
.timeTexts h3,
.coursePeriod {
  text-transform: uppercase;
}

.timeTexts h4 {
  font-weight: 700;
}

.coursePeriod {
  font-size: 20px;
  font-weight: 500;
  width: 50%;
}

@media screen and (max-width: 640px) {
  .timeSection{
    flex-direction: column;
    padding: 50px;
    padding-top: 80px;
  }
  .timeTexts {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  .timeTexts h3, .timeTexts h4{
    font-size: 20px;
    text-align: center;
  }
  .coursePeriod{
    text-align: center;
    margin-top: 25px;
    width: 100%;
  }
  .coursePeriod p{
    font-size: 18px;
    margin-bottom: 10px;
  }
}
