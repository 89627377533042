.arrowIcon {
  bottom: 0;
  right: 0;

  position: fixed;

  background-color: var(--preto);
  
  height: 50px;
  width: 50px;
  margin: 30px;
  font-size: 50px;
  font-weight: 200;
  z-index: 999;
}

.arrowIcon a{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}

@media screen and (max-width: 640px) {
  .arrowIcon {
    display: none;
  }
}
