.footerSection {
  background-color: var(--cinza);
  padding: 50px 100px;
  padding-bottom: 10px;
}

.contentAndImages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contentAndImages div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contentAndImages div:last-child img:first-child {
  width: 15%;
  object-fit: cover;

  padding-right: 20px;
  margin-right: 40px;
  border-right: 2px solid var(--preto);
}

.contentAndImages div:last-child img:last-child {
  width: 50%;
  object-fit: contain;
}

.footerSection h3 {
  text-transform: uppercase;
}

.footerSection ul {
  margin: 0;
  padding: 0;
}
.footerSection ul li {
  display: inline;
  font-size: 30px;
  margin-right: 10px;
}

.contentIcons p {
  font-size: 22px;
}

.footerSection img {
  width: 20%;
}

.footerSection p:last-child {
  text-align: center;
  margin-top: 50px;
  opacity: 48%;
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  .footerSection {
    padding: 50px;
  }

  .contentAndImages div:last-child {
    justify-content: center;
  }
  
  .contentAndImages {
    justify-content: center;
    flex-direction: column;
  }

  .contentAndImages div:last-child img:first-child {
    width: 28%;
  }
  
  .contentAndImages div:last-child img:last-child {
    width: 60%;
  }

  .footerSection ul {
    margin-bottom: 30px;
  }
  .footerSection ul li {
    display: inline;
    font-size: 20px;
    margin-right: 10px;
  }

  .contentIcons p {
    font-size: 18px;
  }

  .footerSection p:last-child {
    font-size: 12px;
  }
}
