.scheduleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  padding: 40px;
}

.scheduleTexts {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 70px;
}

.infosSchedule {
  margin-bottom: 50px;
}

.weekDays {
  font-size: 20px;
  font-weight: 400;
}
@media screen and (max-width: 640px) {
  .scheduleSection {
    display: block;
    padding: 50px;
  }

  .infosSchedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 0;
    margin-bottom: 50px;
  }

  .infosSchedule a {
    margin-bottom: 10px;
  }

  .scheduleTexts {
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 3px solid var(--preto);
  }

  .scheduleTexts p {
    margin-bottom: 0;
  }
}
