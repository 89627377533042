.aboutTexts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px 150px;
}

.aboutCourseSection h2 {
  text-transform: uppercase;
}

.aboutContent {
  width: 60%;
}
.aboutCourseSection p {
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
}

.aboutButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutButton a,
.scheduleSection a {
  background-color: var(--preto);
  padding: 20px 50px;
  font-size: 32px;
  color: #fff;
  text-transform: uppercase;

  margin-bottom: -40px;
}

@media screen and (max-width: 640px) {
  .aboutTexts {
    flex-direction: column;
    padding: 50px;
  }

  .aboutContent {
    width: 100%;
    margin-top: 20px;
  }

  .aboutCourseSection p {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .aboutButton a,
  .scheduleSection a {
    padding: 12px 37px;
    font-size: 28px;
  }
}
