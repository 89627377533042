.divMainSection {
  background-image: url("../../img/background.png");
  background-size: cover;
}
.mainSection {
  width: 100% !important;
  height: 100vh;
  padding: 90px 60px;
}

.mainSection nav {
  display: flex;
  justify-content: flex-end;
}

.mainSection ul li:not(:last-child) {
  margin: 20px;
}

.mainSection .navOptions {
  color: #fff;
}
.nameLanguage {
  background-color: #fff;
  color: #000;
  padding: 3px 8px ;
  font-weight: 700;
}
.mainSection ul li,
.mainSection ul li p {
  display: inline;
  padding: 0 !important;

  text-transform: uppercase;
  font-size: 12px;
}

.mainSection section {
  display: flex;
  align-items: center;
  height: 100%;
}

.contentMainSection {
  display: flex;
  align-items: center;

  text-transform: uppercase;
  font-weight: 200;
  color: #fff;
}

.contentMainSection img {
  width: 20%;
}
.textsMainSection h5 {
  letter-spacing: 1.5px;
  font-size: clamp(0.6em, 0.7em + 1vw, 1.9em);
}

.responsiveHeaderSection,
.responsiveLinksMenu {
  display: none;
}

@media screen and (max-width: 640px) {
  .responsiveHeaderSection .responsiveMenuHeader {
    display: flex;
  }

  .responsiveHeaderSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;

    padding: 0 20px;
    z-index: 999;
    background-color: var(--preto);
  }

  .originalBar ul {
    display: none;
  }

  .responsiveOpenMenu {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .responsiveLinksMenu {
    background-color: var(--preto);
    opacity: 97%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
  }

  .navOptions {
    color: var(--cinza);
  }

  .contentMainSection {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .textsMainSection h3 {
    margin: 20px 0 40px 0;
  }

  .contentMainSection img {
    width: 30%;
  }

  .responsiveLinksMenu ul {
    text-align: center;
  }

  .responsiveLinksMenu ul li {
    margin: 20px;
    text-transform: uppercase;
    font-size: 20px;
  }
  .dot {
    width: 5px;
    height: 5px;
    background-color: var(--cinza);
    margin: 0 auto;
    border-radius: 100%;
  }

  .nameLanguage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .nameLanguage p{
    margin: 0;
  }
}
