.modulesSection,
.titleModules {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.titleModules {
  text-transform: uppercase;
  text-align: center;
  background-color: var(--preto);
  width: 100%;
  margin-bottom: 50px;
}

.titleModules h2 {
  background-color: #fff;
  width: 30%;
  margin: 0;
}

.textsModules {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 60%;
}

.textsModules p:last-child {
  font-weight: bolder;
  margin-bottom: 100px;
}

.cardsModules,
.cardsMap {
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  margin-bottom: 30px;
}

.cardModule {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--preto);
  color: #fff;

  height: 400px;
  margin-bottom: 20px;
}

.cardModule h4 {
  border: 2px solid var(--preto);
  background-color: #fff;
  color: var(--preto);

  padding: 7px;
  margin-bottom: 20px;
  width: 100%;

  text-transform: uppercase;
  text-align: center;
}

.cardButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.cardModule button {
  color: #fff;
  display: block;
  text-transform: uppercase;
  text-align: left;
  line-height: 40px;
  font-size: 13.5px;
  padding: 0 30px;
}

.cardModule .nameModule {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  color: var(--preto);

  width: 100%;
}

.nameModule h5 {
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .textsModules {
    font-size: 16px;
    width: 85%;
  }

  .textsModules p:last-child {
    margin-bottom: 60px;
  }

  .cardsModules,
  .cardsMap {
    width: 345px;
    flex-direction: column;
  }

  .titleModules h2 {
    width: 50%;
  }
}
