* {
  font-family: "Montserrat", cursive;
  list-style: none;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: var(--preto);
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
}

*:focus{
  outline: none;
}

:root{
  --preto: #131313;
  --cinza: #EBEBEB
}

::-webkit-scrollbar{
  background-color: #FFF;
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background-color: var(--preto);
  border-radius: 50px;
}

