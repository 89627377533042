.teamSection,
.studentsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 80px 150px;
}

.teamSection h2,
.studentsSection h2 {
  width: 100%;
  padding: 30px;

  background-color: var(--preto);
  color: #fff;

  text-align: center;
  text-transform: uppercase;
}

.teamSection img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

.teamCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  text-align: center;

  padding-top: 80px;
}

.teamCards p {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 640px) {
  .teamSection,
  .studentsSection {
    padding: 50px;
  }

  .teamSection h2,
  .studentsSection h2 {
    padding: 20px;
    width: 90%;
  }

  .teamCards {
    display: block;
    text-align: center;
    padding-top: 50px;
  }
}
