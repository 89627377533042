.studentsCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.studentsSection {
  padding-top: 0;
}

.mapStudents {
  display: flex;
}

.studentsCards div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.studentsCards img {
  width: 60%;
  text-align: center;
  object-fit: cover;
}

.map {
  width: 40%;
  margin: 10px;
  object-fit: contain;
}
.studentsSection h4 {
  margin: 50px;
  padding: 5px 15px;
  font-size: 20px;
  text-transform: uppercase;
  border: 1px solid #000;
}

.studentsCards p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
  width: 100%;
}

.studentsCards h6 {
  font-size: 9px;
}

@media screen and (max-width: 640px) {
  .mapStudents {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .studentsSection h4 {
    margin: 30px;
    padding: 10px;
    font-size: 20px;
    text-transform: uppercase;
    border: 1px solid #000;
  }
  .map {
    width: 100%;
    margin: 30px;
    margin-top: 0;
    object-fit: contain;
  }

  .studentsCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
}
