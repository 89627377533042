.areasSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #fff;
  background-color: var(--preto);
  padding: 90px;
  padding-bottom: 50px;
}

.areasSection h2 {
  text-transform: uppercase;
  margin-bottom: 50px;
}

.cardsAreas {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cardArea {
  border: 3px solid #fff;
  padding: 30px;
  text-align: center;
  width: 80%;
  height: 360px;
  margin: 30px;
}

.cardArea h4 {
  text-transform: uppercase;
  margin: 30px;
}

.cardArea p {
  font-size: 20px;
}

@media screen and (max-width: 640px) {
  .areasSection {
    padding: 50px;
  }
  .areasSection h2 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 22px;
  }

  .cardsAreas {
    flex-direction: column;
  }

  .cardArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 90%;
    height: 260px;
  }

  .cardArea h4 {
    margin: 20px;
  }

  .cardArea p {
    font-size: 16px;
  }
}
