.cardModuleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.cardsModule {
  padding: 70px 50px;
  padding-top: 40px;
  margin-top: 250px;
  width: 85%;
  height: 100%;

  background-color: var(--cinza);
  position: absolute;
}

.cardsModule h2 {
  text-transform: uppercase;
}

.cardsModule button {
  margin-left: auto;
}
.titleButton {
  display: flex;
  justify-content: space-between;
}

.cardsModule h5{
  font-size: 18px;
}
.cardsModule h5:first-child {
  margin-top: 20px;
  text-transform: uppercase;
}
.cardsModule h5:nth-child(2) {
  margin-bottom: 30px;
}

.cardsModule p {
  text-align: justify;
  width: 85%;
  font-size: 18px;
}

.cardsModule img {
  width: 100%;
}
.cardModuleInfos {
  display: grid;
  grid-template-columns: 55% 45%;
}

.cardModuleImg{
  padding: 30px;
}

.cardModuleImg img{
  width: 95%;
  height: 95%;
}

@media screen and (max-width: 640px) {
  .cardModuleInfos {
    display: block
  }

  .cardsModule {
    padding: 30px;
    margin-top: 500px;
    padding-top: 40px;
    width: 90%;
    height: fit-content;
  
    position: absolute;
  }

  .cardsModule p {
    text-align: justify;
    width: 100%;
    font-size: 16px;
  }

  .cardModuleImg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
 
}
